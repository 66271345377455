import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import FeatureGrid from "components/ui/base/FeatureGrid";

export interface AIBenefitsProps {
  customTitle?: string;
}

const AIBenefits: FunctionComponent<AIBenefitsProps> = ({ customTitle }) => {
  const { t } = useTranslation();

  const sections = [
    {
      title: t("components.ui.extended.AIBenefits.sections.1.title"),
      description: t(
        "components.ui.extended.AIBenefits.sections.1.description"
      ),
      image: {
        alt: t("components.ui.extended.AIBenefits.sections.1.title"),
        relativePath: "pages/index/benefits-instant-resolution.jpg",
      },
    },
    {
      title: t("components.ui.extended.AIBenefits.sections.2.title"),
      description: t(
        "components.ui.extended.AIBenefits.sections.2.description"
      ),
      image: {
        alt: t("components.ui.extended.AIBenefits.sections.2.title"),
        relativePath: "pages/index/benefits-assistant-productivity.jpg",
      },
    },
    {
      title: t("components.ui.extended.AIBenefits.sections.3.title"),
      description: t(
        "components.ui.extended.AIBenefits.sections.3.description"
      ),
      image: {
        alt: t("components.ui.extended.AIBenefits.sections.3.title"),
        relativePath: "pages/index/benefits-multilanguage.jpg",
      },
    },
  ];

  return (
    <FeatureGrid
      title={t("components.ui.extended.AIBenefits.title")}
      centeredTitle
      sections={sections}
      bigImages
    />
  );
};

export default AIBenefits;
