import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import PlainHeader from "components/ui/base/PlainHeader";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import Awards from "components/ui/extended/Awards";
import AIBenefits from "components/ui/extended/AIBenefits";
import NumberSteps from "components/ui/base/NumberSteps";
import FullWidthImage from "components/ui/base/FullWidthImage";
import { SIGNUP_URL } from "constants/paths";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import PlatformFeatures from "components/ui/extended/PlatformFeatures";
import TestimonialWithImageLaurentiu from "components/ui/extended/TestimonialWithImageLaurentiu";

interface AICustomerServicePageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    beforeImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    afterImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: AICustomerServicePageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      location={location}
      layoutSettings={{ withCta: false, withMenu: false, withFooter: false }}
    >
      <SEO
        title={t("pages.ai.seo.title")}
        description={t("pages.ai.seo.description")}
        image={{
          relativePath: "meta/customerly-ai.jpg",
          alt: "Live Chat with Superpowers",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.ai.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.ai.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(`pages.ai.faq.${index}.question`),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(`pages.ai.faq.${index}.answer`),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeader
        title={t("pages.index.title")}
        titleMaxWidth={1060}
        descriptionMaxWidth={900}
        description={t("pages.index.description")}
        ctaRegister={{ show: true }}
        image={{
          relativePath: "pages/index/AI-customer-service-solution.jpg",
          alt: "Customer support Chatbot with AI",
        }}
        ctaGetDemo={{
          show: true,
        }}
      />

      <Customers />

      <AIBenefits />

      <NumberSteps
        title={t("pages.index.steps.title")}
        elements={Array.from(Array(4).keys()).map((i) => ({
          title: t(`pages.index.steps.${i + 1}.title`),
          description: t(`pages.index.steps.${i + 1}.description`),
        }))}
      />

      <FullWidthImage
        title={t("pages.index.help-desk.title")}
        description={t("pages.index.help-desk.description")}
        cta={{
          link: SIGNUP_URL,
          text: t("components.ui.extended.RegisterCTA.getStarted"),
        }}
        descriptionMaxWidth={900}
        image={{
          relativePath: "pages/index/help-desk-status.jpg",
          alt: t("pages.index.help-desk.title"),
        }}
      />

      <ImageWithDescription
        title={t("pages.index.aura-training.title")}
        description={t("pages.index.aura-training.description")}
        cta={{
          link: SIGNUP_URL,
          text: t("components.ui.extended.RegisterCTA.getStarted"),
        }}
        image={{
          relativePath: "pages/index/AI-learn.jpg",
          alt: t("pages.index.aura-training.title"),
          fullWidth: true,
          position: "right",
        }}
      />

      <ImageWithDescription
        title={t("pages.index.escalation.title")}
        description={t("pages.index.escalation.description")}
        cta={{
          link: SIGNUP_URL,
          text: t("components.ui.extended.RegisterCTA.getStarted"),
        }}
        image={{
          relativePath: "pages/index/AI-smart-escalation.jpg",
          alt: t("pages.index.escalation.title"),
          fullWidth: true,
          position: "left",
        }}
      />

      <FullWidthImage
        title={t("pages.index.missions.title")}
        description={t("pages.index.missions.description")}
        descriptionMaxWidth={700}
        cta={{
          link: SIGNUP_URL,
          text: t("components.ui.extended.RegisterCTA.getStarted"),
        }}
        image={{
          relativePath: "pages/index/ai-mission-setup-refund.jpg",
          alt: t("pages.index.missions.title"),
        }}
      />

      <ImageWithDescription
        title={t("pages.index.automate.title")}
        description={t("pages.index.automate.description")}
        cta={{
          link: SIGNUP_URL,
          text: t("components.ui.extended.RegisterCTA.getStarted"),
        }}
        image={{
          relativePath:
            "pages/index/full-scale-support-automation-with-ai-chatbot.jpg",
          alt: t("pages.index.automate.title"),
          fullWidth: true,
          position: "right",
        }}
      />

      <PlatformFeatures location={location} />

      <TestimonialWithImageLaurentiu />
      <Awards />
      <RegisterForCustomerService />
      <Customers />
    </GlobalWrapper>
  );
};

export const AICustomerServicePagePropsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/customerly-ai.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    beforeImage: file(
      relativePath: { eq: "pages/ai/before.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    afterImage: file(
      relativePath: { eq: "pages/ai/after.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
