import styled from "styled-components";

const StyledAwards = styled.section`
  text-align: center;
  white-space: pre-line;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 32px;
  margin: 120px;
`;

export { StyledAwards };
