import React from "react";
import { useTranslation } from "react-i18next";
import TestimonialWithImage from "components/ui/base/TestimonialWithImage";

const TestimonialWithImageLaurentiu = () => {
  const { t } = useTranslation();

  return (
    <TestimonialWithImage
      url={"/success-stories/paymo-project-management/"}
      quote={t("components.ui.extended.TestimonialWithImageLaurentiu.quote")}
      quotee={t("components.ui.extended.TestimonialWithImageLaurentiu.quotee")}
      company={"Paymo"}
      image={{
        relativePath: "components/testimonials/laurentiu.png",
        alt: t("components.ui.extended.TestimonialWithImageLaurentiu.quotee"),
      }}
    />
  );
};

export default TestimonialWithImageLaurentiu;
