import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import FeatureGrid from "components/ui/base/FeatureGrid";

interface PlatformFeaturesProps {
  location: Location;
}

const PlatformFeatures: FunctionComponent<PlatformFeaturesProps> = ({
  location,
}) => {
  const { t } = useTranslation();

  return (
    <FeatureGrid
      id={"platform-features"}
      title={t("components.ui.extended.PlatformFeatures.title")}
      description={t("components.ui.extended.PlatformFeatures.description")}
      alternativeColors
      bigImages
      sections={[
        "crm",
        "engagement",
        "workflow",
        "nps",
        "surveys",
        "integrations",
      ].map((index) => {
        return {
          title: t(`components.ui.extended.PlatformFeatures.${index}.title`),
          description: t(
            `components.ui.extended.PlatformFeatures.${index}.subtitle`
          ),
          image: {
            relativePath: t(
              `components.ui.extended.PlatformFeatures.${index}.image`
            ),
            alt: "",
          },
          links: [
            {
              text: t(`components.ui.extended.PlatformFeatures.${index}.cta`),
              path: t(`components.ui.extended.PlatformFeatures.${index}.path`),
            },
          ],
        };
      })}
    />
  );
};

export default PlatformFeatures;
