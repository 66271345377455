import React from "react";
import { StyledAwards } from "./index.style";
import StaticSvg from "components/core/StaticSvg";

const Awards = () => {
  return (
    <StyledAwards>
      <StaticSvg
        alt={"G2Crowd Best Live Chat 2019"}
        src={"awards/get-app-best-live-chat-2020.svg"}
        width={100}
      />
      <StaticSvg
        alt={"G2Crowd Best Live Chat 2019"}
        src={"awards/front-runner-app-best-live-chat-2022.svg"}
        width={100}
      />
      <StaticSvg
        alt={"Best Live Chat 2022"}
        src={"awards/capterra-best-live-chat-2022.svg"}
        width={100}
      />
    </StyledAwards>
  );
};

export default Awards;
